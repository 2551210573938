import Icon from '@components/core/media/Icon'
import { alpha, Box, IconButton, Theme } from '@mui/material'
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
} from 'react'
import { YouTubeEvent } from 'react-youtube'

import ReactYouTubePlayer from 'react-youtube'
import Image from '../Image'
import { YouTubePlayer as YoutubePlayerType } from 'youtube-player/dist/types'
import { trackEvent } from '@utils/tracking'
import {
  kEventCategoryInteraction,
  kEventActionPlayVideo,
} from '@utils/constants'
import Copy from '@components/core/text/Copy'

export type YouTubeProps = {
  youtubeId: string
  videoTitle?: string
  thumbnail?: MCDC.Props.IAssetImage
  alt?: string
  isConsented?: boolean
  isAutoPlay?: boolean
  onClick?: () => void
  sx?: MCDC.Props.IDefault['sx']
  rounded?: boolean
  description?: string
  onPlaying?: (callback: (param: YouTubeEvent) => void) => void
  trackingType?: string
  fixedPlayerHeight?: string
}

const YouTubePlayer = forwardRef(function YouTubeInner(
  {
    youtubeId,
    videoTitle,
    thumbnail,
    alt,
    isConsented,
    isAutoPlay = false,
    onClick,
    sx,
    rounded,
    description,
    onPlaying,
    trackingType,
    fixedPlayerHeight,
  }: YouTubeProps,
  ref
): ReactElement {
  const ytRef = useRef(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isShowingPlayer, setIsShowingPlayer] = useState(false)
  const [isShowingOverlay, setIsShowingOverlay] = useState(true)
  const [player, setPlayer] = useState<YoutubePlayerType | null>(null)

  useEffect(() => {
    if (isInitialized) {
      player?.playVideo()
      setIsShowingOverlay(false)
      trackEvent(
        kEventCategoryInteraction,
        kEventActionPlayVideo,
        `youtubeId:${youtubeId} - title:"${
          videoTitle ? videoTitle : thumbnail?.file.fileName
        }"`
      )
    } else setIsShowingOverlay(true)
  }, [youtubeId, isInitialized])

  useEffect(() => {
    if (!isInitialized && player && isAutoPlay && isShowingOverlay) {
      initVideo()
    }
  }, [isAutoPlay, isInitialized, isShowingOverlay, player])

  useEffect(() => {
    if (isConsented && !isShowingPlayer) {
      setIsShowingPlayer(true)
    } else if (!isConsented && isShowingPlayer) {
      setIsShowingPlayer(false)
      setIsInitialized(false)
      setPlayer(null)
    }
  }, [isConsented, isShowingPlayer])

  function initVideo() {
    if (!player || !isConsented) return
    setIsInitialized(true)
  }

  function playVideo() {
    if (typeof onClick === 'function') {
      onClick()
    }
    if (!isConsented) return
    initVideo()
  }

  function onVideoPlaying(param: YouTubeEvent) {
    if (onPlaying) {
      onPlaying(param)
    }
    trackEvent(
      kEventCategoryInteraction,
      trackingType ? trackingType : kEventActionPlayVideo,
      `youtubeId:${youtubeId} - title:"${thumbnail?.file?.fileName}"`
    )
  }

  useImperativeHandle(ref, () => ({
    playVideo() {
      initVideo()
      setIsInitialized(true)
    },
  }))
  return (
    <Box
      sx={[
        (muiTheme: Theme) => ({
          '& .uc-embedding-container': {
            position: 'absolute',
            zIndex: 100,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '& .uc-embedding-wrapper': {
              padding: muiTheme.spacing(4),
              borderRadius: 0,
              '& .uc-embedding-more-info': {
                borderRadius: 0,
              },
              '& .uc-embedding-accept': {
                borderRadius: 0,
              },
            },
            '& img': {
              display: 'none',
            },
          },
        }),
        sx,
      ]}
    >
      <Box
        sx={(theme: Theme) => ({
          position: 'relative',
          width: '100%',
          height: fixedPlayerHeight ? fixedPlayerHeight : '440px',
          paddingTop: 'calc(4 / 3 * 100%)',
          borderRadius: rounded ? '10px' : 0,
          backgroundColor: 'common.white',
          overflow: 'hidden',
          '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
          [theme.breakpoints.up('md')]: {
            paddingTop: 'calc(9 / 16 * 100%)',
          },
        })}
      >
        {isShowingPlayer && (
          <Box sx={{ visibility: !isInitialized ? 'hidden' : undefined }}>
            <ReactYouTubePlayer
              ref={ytRef}
              videoId={youtubeId}
              opts={{
                playerVars: {
                  // autoplay: isAutoPlay ? 1 : 0,
                  rel: 0,
                },
              }}
              onReady={({ target }) => {
                setPlayer(target)
              }}
              onPlay={onVideoPlaying}
            />
          </Box>
        )}
        {isShowingOverlay && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'text.primary',
            }}
            onClick={playVideo}
          >
            {thumbnail && (
              <Image
                {...thumbnail}
                objectFit="cover"
                alt={alt || `Youtube Video ${youtubeId}`}
                sx={{ height: '100%' }}
              />
            )}
            {description && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'end',
                  justifyContent: 'center',
                  position: 'absolute',
                  bottom: 4,
                  left: 0,
                }}
              >
                <Copy
                  sx={{
                    pb: 1,
                    fontWeight: 'bold',
                  }}
                  variant="body1"
                >
                  {description}
                </Copy>
              </Box>
            )}
            <Box
              sx={(theme: Theme) => ({
                position: 'absolute',
                top: '50%',
                left: '0',
                right: '0',
                bottom: '0',
                px: theme.gutter('sm'),
                pb: theme.gutter('sm'),
                justifyContent: 'flex-start',
                color: 'common.white',
                textAlign: 'center',
                backgroundColor: description
                  ? null
                  : alpha(theme.palette.common.black, 0.5),
                background: description
                  ? null
                  : 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
              })}
            >
              <IconButton
                sx={{
                  mt: '-40px',
                  color: 'primary.main',
                }}
              >
                <Icon variant="play_circle" size="xlarge" />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
})

export default YouTubePlayer
